const fontStyles = {
	regular: `'Inter', sans-serif`,
	bold: `'Inter', sans-serif`,
	black: `'Inter', sans-serif`,
	mono: `'Inter', sans-serif`,
	monoBold: `'Inter', sans-serif`,
	compressedBlack: `GT-America-Compressed-Black`,
	compressedMedium: `GT-America-Compressed-Medium`,
};

export default fontStyles;
