import { CurrencyKey } from "./currency";

export const PROD_HOSTNAME = "kwenta.eth.limo";

export const EXTERNAL_LINKS = {
  Trading: {
    Legacy: "https://legacy.kwenta.io/exchange",
    PerpsV1: "https://v1.kwenta.eth.limo",
    DexAG: "https://dex.ag/",
    Uniswap: "https://uniswap.exchange/",
    OneInch: `https://1inch.exchange/`,
    OneInchApi: {
      ethereum: "https://api.1inch.io/v5.0/1/",
      optimism: "https://api.1inch.io/v5.0/10/",
    },
    OneInchLink: (from: CurrencyKey, to: CurrencyKey) =>
      `https://1inch.exchange/#/${from}/${to}`,
    OptimismTokenBridge: "https://gateway.optimism.io",
  },
  Options: {
    Trade: "https://options.kwenta.eth.limo/#/trade",
  },
  Synthetix: {
    Home: "https://www.synthetix.io",
    Litepaper: "https://docs.synthetix.io/litepaper/",
  },
  Social: {
    Twitter: "https://twitter.com/opxfinance",
    Discord: "https://discord.com/invite/mNdHE52zZp",
    Telegram: "https://t.me/opxfinance",
    Medium: "https://medium.com/@opxfi",
    Reddit: "https://www.reddit.com/r/OPX_finance/",
    Mirror: "https://www.reddit.com/r/OPX_finance/",
    GitHub: "https://discord.com/invite/mNdHE52zZp",
  },
  TokenLists: {
    Zapper: "https://zapper.fi/api/token-list",
  },
  Docs: {
    DocsRoot: "https://docs.opx.finance/",
    FeeReclamation: "https://docs.opx.finance/",
    HowToTrade: "https://docs.opx.finance/",
    Governance: "https://docs.opx.finance/",
    DaoRoles: "https://docs.opx.finance/",
    HowToUse: "https://docs.opx.finance/",
    Perpetuals: "https://docs.opx.finance/",
    Spot: "https://docs.opx.finance/",
    DevDao: "https://docs.opx.finance/",
    MarketingDao: "https://docs.opx.finance/",
    Faq: "https://docs.opx.finance/",
    CrossMarginFaq:
      "https://docs.opx.finance/osyn-finance/smart-margin/faq#what-is-smart-margin",
    CrossMarginFaq2:
      "https://docs.opx.finance/osyn-finance/smart-margin/faq#is-smart-margin-better-than-isolated-margin",
    CrossMarginFaq3:
      "https://docs.opx.finance/osyn-finance/smart-margin/faq#how-will-a-smart-margin-account-affect-my-trading",
    Staking: "https://docs.opx.finance/",
    TradingRewardsV2: "https://docs.opx.finance/",
    RewardsGuide: "https://docs.opx.finance/",
  },
  Optimism: {
    Home: "https://optimism.io/",
  },
  Trade: {
    PerpsV2:
      "https://kwenta.eth.limo/market/?accountType=cross_margin&asset=sETH",
    Spot: "https://kwenta.eth.limo/exchange/",
    V1: "https://v1.kwenta.eth.limo/dashboard",
  },
  Governance: {
    Kips: "https://gov.kwenta.eth.limo/all-kips",
    Vote: "https://snapshot.org/#/kwenta.eth",
  },
  Competition: {
    LearnMore:
      "https://mirror.xyz/kwenta.eth/s_PO64SxvuwDHz9fdHebsYeQAOOc73D3bL2q4nC6LvU",
  },
};
